import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// images
import logo from "../images/matt-cotter-logo-w.svg"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`Matt Cotter`, `Web Developer`, `Dayton, OH`, `Cincinnati, OH`, `Columbus, OH`, `Vue Developer`, `React Developer`, `Freelance`]} />
    <img src={ logo } alt="Matt Cotter's Logo" className="matt-cotter-design" />
  </Layout>
)

export default IndexPage
